.custom-bullet li {
    position: relative;
    padding-left: 2rem;
}

.custom-bullet li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 6px;
    width: 28px;
    height: 28px;
    background-image: url("../public/bulletin-point.svg");
    background-size: contain;
    background-repeat: no-repeat;
}

html {
    scroll-behavior: smooth;
}